import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sinkable"
export default class extends Controller {
  static targets = ["type"];

  handleClick(event) {
    const button = event.target;
    const unit = button.textContent.trim(); // Get the unit from the button text

    // Add the 'white-text' class and change the color of the clicked element
    button.classList.add('white-text');
    button.classList.remove('white');
    button.style.color = '#CD7854';

    // Remove the 'white-text' class and change the color of the other elements
    this.element.querySelectorAll('.sinkable').forEach((el) => {
      if (el !== button) {
        el.classList.remove('white-text');
        el.classList.add('white', 'black-text');
        el.style.color = ''; // Reset color
      }
    });

    // Update the hidden field value
    this.typeTarget.value = unit;
    this.typeTarget.dispatchEvent(new Event('change')); // Trigger the change event
  }
}
