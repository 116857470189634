import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "total"]
  connect() {
    this.calculate();
  }

  calculate() {
    let total = 0;
    this.fieldTargets.forEach((field) => {
      total += Number(field.value) || 0;
    });
    if (this.totalTarget.classList.contains('red-text') || this.totalTarget.classList.contains('green-text')) {
      const headcountElement = document.getElementById('assessment_headcount');
      const headcount = Number(headcountElement.value);
      const difference = headcount - total;
      if (difference == 0) {
        this.totalTarget.classList.remove('red-text', 'invalid');
        this.totalTarget.classList.add('green-text', 'valid');
      } else {
        this.totalTarget.classList.add('red-text', 'invalid');
        this.totalTarget.classList.remove('green-text', 'valid');
      }
    }
    this.totalTarget.value = total;
    this.totalTarget.dispatchEvent(new Event('input'));
  }
}